<template>
  <!-- <b-row> -->
  <ThumbsGallery
    v-if="confPhotos.length"
    class="project-details__carasoul mt-0 photo-media"
    :conf-photos="confPhotos"
  >
  </ThumbsGallery>
  <section v-else-if="requestChecker" class="py-5 px-2">
    <content-placeholders :rounded="true" :centered="true">
      <content-placeholders-img style="height: 500px" />
      <section class="d-flex justify-content-between">
        <content-placeholders-img
          v-for="index in 3"
          :key="index"
          style="height: 100px; width: 32%; margin: 20px 0 0"
        />
      </section>
    </content-placeholders>
  </section>

  <!-- </b-row> -->
</template>

<script>
export default {
  name: "PhotoMedia",
  components: {
    ThumbsGallery: () => import("@/components/ThumbsGallery")
  },

  props: {
    confPhotos: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  mounted() {
    // to remove chemmer if there is no data
    setTimeout(() => {
      this.requestChecker = false;
    }, 10000);
  },
  data() {
    return {
      requestChecker: true
    };
  }
};
</script>
